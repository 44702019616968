.idioma-seleccionado { 
    text-decoration: underline;
}

.pointer {
    cursor: pointer;
}

.link {
    color: rgba(0,0,0,.55);
    text-decoration: none;
}